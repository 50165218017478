@import url('https://fonts.googleapis.com/css2?family=Istok+Web&display=swap');

@media (prefers-reduced-motion: no-preference) {
  .vs-snip {
    animation: slide2 1s alternate linear;
  }
}

*{
  scrollbar-width: none; /* for Firefox */
}

*::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.App {
  text-align: center;
  position: relative;
  height:100vh;
  width: 100vw;
  font-size: calc(6px + min(1vw,1vh));
  font-family: 'Istok Web', sans-serif;
  overflow-y: scroll;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  background-color: black;
  color: white;
}

header img{
  cursor: pointer;
}

.App::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.active{
  font-weight: bold;
  text-shadow: .1em .1em .2em rgb(77, 66, 223, 0.733);
  box-shadow: 0 .2em .2em -.2em rgba(77, 66, 223, 0.877);
}

.progress-ball{
  position: absolute;
  height: .6em;
  width: .6em;
  border-radius: 3px;
  rotate: 45deg;
  background-color: rgb(77, 66, 223, 0.733);
  box-shadow: -0.09em -.1em .3em .01em white;
  left: 0;
  margin: 0;
  box-sizing: border-box;
  bottom: -.5em;
}

.progress-ball-container{
  position: absolute;
  bottom: 0;
  width: 92%;
  height: 2em;
  left: 3.5%;
}

.vs-snip{
  position: absolute;
  right: 0;
  top: 0;
  opacity: 15%;
  height: 100%;
  z-index: 0;
}

.logo-container{
  width: 9vh;
  height: 10vh;
  position: relative;
}

.logo-caption{
  position: absolute;
  display: none;
  border-radius: 20px;
  width: auto;
  height: 50%;
  left: 2vw;
  background-color: rgb(180, 178, 212);
  color: black;
  justify-content: center;
  align-items: center;
  animation: fade 7s linear forwards;
  z-index: 4;
}

.logo-caption span{
  overflow: hidden; 
  border-right: .15em solid black; 
  white-space: nowrap; 
  margin: 0 auto; 
  letter-spacing: .15em;
  padding: 0 .5em;
  animation: 
    typing 1.5s steps(40, end),
    blink-caret .75s step-end infinite;
  position: relative;
  z-index: 3;
}

.logo-caption::after{
  content: '';
  position: absolute;
  top: -2%;
  left: 5%;
  height: 30%;
  width: 10%;
  background: inherit;
  transform: rotate(28deg);
  border-radius: 2px;
  z-index: 1;
}

.logo{
  height: 100%;
  width: 100%;
}

nav{
  display: flex;
  width: 60%;
  height: 100%;
  position: relative;
  margin-left: 20%;
}

h4{
  margin: 0;
  color:rgba(117, 113, 168, 0.842);
  padding: 0;
}

.headerText h2{
  padding: 0;
  margin: 0;
}

.nav-list{
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 0 0;
  box-sizing: border-box;
}

.nav-list li{
  font-size: 150%;
  cursor: pointer;
  padding: 0 .5em;
}

.arMode .nav-list li{
  font-size: 2.5em;
  background-color: rgb(177, 177, 177);
  color: black;
  text-shadow: none;
  width: 200px;
  box-sizing: border-box;
  font-family:Arial, Helvetica, sans-serif;
  border-radius: 3px;
  border: 5px solid rgb(177, 177, 177);
  animation: popOut 800ms linear forwards;
}

.arMode .nav-list li[aria-label ="hovered"]{
  color:rgba(176, 218, 247, 0.982);
  opacity: 100%;
}


.arMode .nav-list li[aria-selected ="true"]{
  opacity: 100%;  
  animation: press 350ms ease-out forwards;
}

.arMode .nav-list li[aria-label ="unpressed"]{
  opacity: 100%;
  box-shadow: -.2em .2em .1em .3em rgb(48, 48, 48);
}

.arMode .section{
  opacity: 50%;
  animation: arFade 2.5s 1.5s forwards;
}

.arMode .progress-ball-container{
  display: none;
}

.arMode .contact-icons *[aria-label="hovered"]{
  filter: brightness(200%)
}

ul{
  padding: 0;
}

.nav-list li:hover{
  text-shadow:1px 1px 2px rgba(77, 66, 223, 0.733);
}

.clickable{
  cursor: pointer;
}

h1{
  margin: 0;
  font-size: 600%;
  font-weight: 500;
}

h3{
  margin: 0;
  color:rgba(180, 178, 212, 0.825);
}

header{
  box-shadow: 0px -10px 20px 10px rgba(77, 66, 223, 0.733);
  background-color: black;
  height: 10vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  padding: .2em .5em;
  box-sizing: border-box;
  z-index: 3;
}

.arMode header{
  background-color: transparent;
}

.header-container{
  position: absolute;
  top: 0;
}

.Cool-letter{
  cursor: pointer;
}

.Cool-letter:hover{
  animation: razzle .5s infinite linear;
}

.body{
  height: 90%;
  width: 100%;
  position: relative;
}

.section{
  padding-top: 15vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  overflow-x: hidden;
}


.arMode .section{
  max-height: 100vh;
}

.arMode .Project-list-item{
  width: 35em;
  height: 20em;
}

.column{
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.full-width{
  width: 100%;
}

.row{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

.headerText {
  text-align: left;
  font-weight: 900;
  width: 100%;
  height: auto;
  position: relative;
  flex-shrink: 1;
  color: rgba(77, 66, 223, 0.733);
  font-size: 300%;
  padding: 0 .5em ;
  box-sizing: border-box;
  margin: 0;
}

#about{
  box-sizing: border-box;
  padding-left: 1em;
}

#work{
  background-color: rgba(15, 13, 41, 0.733);
  position: relative;
}

.icons-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.icons-container img{
  margin: 0 1em;
  max-width: 5em;
  height: auto;
}

.icons-container img:hover{
  filter: brightness(1.7)
}

.Project-list-item:hover .project-list-desc{
  animation: slidefadeup 700ms forwards linear, colorBackground 1s 700ms linear forwards;
}

#topLeft{
  top: 1em;
  left: 1em;
}

#topRight{
  top: 1em;
  right: 1em;
}

.projectIcon{
  margin: 0;
  padding: 0;
  width: 5em;
  height: 5em;
  position: absolute;
  cursor: pointer;
  z-index: 20;
}

.projectIcon img{
  width: 100%;
  height: 100%;
}

.project-list-desc{
  height: 100%;
  width: 100%;
  z-index: 2;
  display: flex;
  opacity: 0%;
  flex-direction: column;
  justify-content: center;
  text-shadow: white 0 0 2em;
}

.project-list-desc h3{
  font-size: 3em;
  padding: 0;
}

.project-list-desc h4{
  font-size: 1.2em;
}

.tech-tiles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1vh;
}

.image-gallery img{
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.image-gallery{
  position: relative;
  height: 50%;
  width: 100%;
}

#contact{
  display: flex;
  position: relative;
  justify-content: center;
}

#contact a{
width: 15%;
margin: 5%;
padding: 0;
}

#contact #email-icon-container{
  width: 15%;
  margin: 5%;
  padding: 0;
}

.svgPath{
  position: fixed;
  left: 20vw;
  width: 60vw;
  height: auto;
  transform: rotate(-10deg);
  opacity: 70%;
  bottom: 8%;
  overflow: show;
  -webkit-filter: 
  drop-shadow( 3px 3px .3em rgba(111, 103, 216, 0.733))
  drop-shadow( -3px -3px .3em rgba(22, 1, 247, 0.916))
  ;
  filter: 
  drop-shadow( 3px 3px .3em rgba(111, 103, 216, 0.733))
  drop-shadow( -3px -3px .3em rgba(22, 1, 247, 0.916));
  z-index: 0;
}


#contact #email-icon{
cursor: pointer;
}

#contact img{
  padding: 0;
  width: 100%;
}

#contact img:hover{
  filter: brightness(1.3);
}

.Project-list {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  padding: 1em;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.project-tech{
  position: absolute;
  bottom: 0;
  left: .5em;
  margin: .1em;
}

.project-tech span{
  text-shadow: white 0 0 .2em ;
}

.Project-list-item {
  background-color:rgba(22, 19, 59, 0.719);
  position: relative;
  width: 40vw;
  height: 20vw;
  margin: 1vw;
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  z-index: 3;
  opacity: 80%;
  border-radius: 5px;
  overflow: hidden;
  filter: grayscale(80%);
}

.Project-list-item:hover {
  animation: colorIn 500ms linear forwards;
}

#upOne{
  animation: slidefadeup 700ms forwards linear;
  opacity: 0;
}

#upTwo{
  animation: slidefadeup 700ms forwards linear;
  animation-delay: 600ms;
  opacity: 0;
}

#upThree{
  animation: slidefadeup 700ms forwards linear;
  animation-delay: 1100ms;
  opacity: 0;
}

.contact-icons{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  margin-top: -10vh;
  opacity: 85%;
}

.headerText p{
  text-align: left;
  font-weight: 900;
  min-width: 85%;
  width: min-content;
  position: relative;
  font-size: 100%;
  box-sizing: border-box;
  padding: .5em 0 .5em .5em;
  color:rgba(111, 103, 216, 0.733);
  margin: 0;
}

#about-me{
  padding: 2em;
  box-sizing: border-box;
}

.projectBackgroundImage{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
}

.cameraCanvas{
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: black;
  position: fixed;
  z-index: -1;
  padding: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}


.input_video{
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
  transform: rotateY(180deg);
  -webkit-transform:rotateY(180deg); /* Safari and Chrome */
  -moz-transform:rotateY(180deg); /* Firefox */
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arButton{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 5em;
  bottom: 0;
  right: 1em;
}

.arButton img{
  height: 50%;
  width: auto;
}

.output_canvas{
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  min-height: 100%;
  z-index:10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fingerTracker{
  background-color: rgba(80, 79, 79, 0.467);
  box-shadow: rgba(95, 188, 251, 0.982) 0 0 10px 5px;
  position: absolute;
  z-index: 200000;
  width: 2em;
  height: 2em;
  border-radius: 50%;
}


#clickingFlash {
  box-shadow: rgb(95, 188, 251) 0 0 13px 8px, rgba(123, 1, 238, 0.5) 0 0 15px 10px;
}

#thumb{
  box-shadow: rgba(251, 95, 95, 0.682) 0 0 1em 1em;
}

#middleFing{
  box-shadow: rgba(251, 251, 95, 0.682) 0 0 1em 1em;
}

#ringFing{
  box-shadow: rgba(103, 251, 95, 0.682) 0 0 1em 1em;
}

#pinky{
  box-shadow: rgba(243, 95, 251, 0.682) 0 0 1em 1em;
}

.arMode{
  background-color: transparent;
}

.hidden{
  display: none;
}

.testButton{
  position: absolute; 
  top: 0;
  left: 50%;
  z-index: 1000000;
}

.loadingScreen{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8em;
  backdrop-filter: blur(18px);
}

.colorsContainer{
  height: 50%;
  width: 10em;
  background-color: rgba(247, 183, 194, 0.088);
  position: absolute;
  top: 30%;
  right: 1em;

  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: none;
  flex-direction: column;
}


/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes fade {
  from { opacity: 100% }
  to { opacity : 0% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color:rgba(15, 13, 41, 0.733); }
}

@keyframes slide2{
  0% {
    transform: translatex(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes arFade{
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 50%;
    background-color: transparent;
  }
}

@keyframes press{
  0%{
    box-shadow: -.2em .2em .1em .3em rgb(48, 48, 48);
    transform: translateX(0) translateY(0);
  }
  50%{
    box-shadow: -.1em .1em .05em .15em rgb(48, 48, 48);
    transform: translateX(-.2em) translateY(.2em);
  }
  70%{
    box-shadow: -.2em .2em .1em .3em rgb(48, 48, 48);
    transform: translateX(0) translateY(0);
  }
  100%{
    box-shadow: -.2em .2em .1em .3em rgb(48, 48, 48);
    transform: translateX(0) translateY(0);
  }
}

@keyframes popOut{
  0%{
    box-shadow: -.1em .1em .05em .15em rgb(48, 48, 48);
    transform: translateX(-.2em) translateY(.2em);
  }
  50%{
    box-shadow: -.1em .08em .1em .1em rgb(177, 177, 177), -.2em .2em .1em .3em rgb(48, 48, 48),-.2em .2em 0em .3em rgb(3, 3, 3);
    transform: translateX(0) translateY(0);
  }
  100%{
    box-shadow: -.1em .08em .1em .1em rgb(177, 177, 177), -.2em .2em .1em .3em rgb(48, 48, 48),-.2em .2em 0em .3em rgb(3, 3, 3);
    transform: translateX(0) translateY(0);
  }
}

@keyframes slidefadeup {
  from { 
    opacity: 0%;
    transform: translateY(6em)}
  to { 
    opacity : 100%;
    transform: translateY(0);
   }
}

@keyframes colorIn {
  from { 
    opacity: 80%;
    filter: grayscale(20)
  }
  to { 
    opacity: 100%;
    filter: grayscale(0)
   }
}

@keyframes colorBackground{
  from { 
    background-color: rgba(128, 128, 128, 0);
    filter: blur(0)
  }
  to { 
    background-color:rgba(3, 1, 7, 0.945);
    filter: blur(20%);
   }
}

@keyframes fadeIn{
  from { 
    opacity: 0%;
  }
  to { 
    opacity: 100%;
   }
}

@media only screen
and (max-aspect-ratio: 1/1) {

  .svgPath{
    position: fixed;
    top: 35%;
    left: 2.5vw;
    width: 95vw;
    height: auto;
  }

  .arButton{
    display: none;
  }

  .section{
    padding-top: 12vh;
  }
  .image-gallery{
    height: 40%;
  }
  .headerText p{
    max-width: 100%;
    padding: 1em 0;
    font-size: 80%;
  }

  .headerText{
    padding: .1em;
  }

  #contact a{
    height: 30%;
    width: 30%;
  }

  #contact #email-icon-container{
    width: 30%;
    height: 30%;
  }

  .Project-list-item{
    width: 80vw;
    height: 40vw;
  }
}

